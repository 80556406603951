@charset "utf-8";

/*!===========================
このcssはSassから生成されていますので、
直接編集しないようご注意ください。
===========================*/

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

html {
	font-family: 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', '游明朝', 'Yu Mincho', '游明朝体', 'YuMincho', 'Times New Roman', 'ＭＳ 明朝', 'MS Mincho', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
	font-size: 62.5%;
	color: #000;
	background-color: #fff;
}
%font01 {
	font-family: 'Montserrat', 'Hiragino Kaku Gothic ProN', YuGothic, 'Yu Gothic', Meiryo, sans-serif;
}
body {
	line-height: 1.8;
	font-size: 1.6em;
}
a {
	color: #000;
	&:hover {
		text-decoration: none;
		opacity: .8;
	}
}
figure {
	text-align: center;
}
img {
	max-width: 100% !important;
	height: auto !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.4;
	font-weight: inherit;
}
hr {
	clear: both;
	border: none;
	margin: 60px 0;
	border-top: 1px solid;
}
table {
	width: 100%;
	border: none;
	border-spacing: 2px;
	> tbody {
		> tr {
			> th,
			> td {
				font-size: 1.4rem;
				padding: 20px;
			}
			> th {
				width: 25%;
				font-weight: inherit;
				text-align: left;
				color: #fff;
				background: #4D4D4D;
			}
			> td {
				background: #F6F5F3;
			}
		}
	}
	&.list > tbody > tr > th {
		width: auto;
	}
	> caption {
		font-weight: bold;
		text-align: inherit;
	}
}
/* class
============================== */
.sp {
	display: none;
}
.clearfix::after {
	content: "";
	display: block;
	clear: both;
}
.right {
	text-align: right !important;
}
.center {
	text-align: center !important;
}
.aligncenter {
	display: block;
	margin: 0 auto;
}
.alignleft,
.alignright {
	max-width: 52% !important;
	margin-bottom: 20px;
}
.alignleft {
	float: left;
	margin-right: 40px;
}
.alignright {
	float: right;
	margin-left: 40px;
}
.mtop0 {
	margin-top: 0 !important;
}
.col {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	> * {
		width: calc(50% - 20px);
		margin-top: 20px !important;
		&:nth-child(-n+2) {
			margin-top: 0 !important;
		}
	}
	&.-img {
		> * {
			width: calc(54.6% - 40px);
		}
		> figure {
			width: 45.4%;
		}
	}
	&.reverse {
		flex-direction: row-reverse;
	}
}
.btn {
	display: block;
	position: relative;
	max-width: 320px;
	line-height: 1.4;
	text-align: center;
	text-decoration: none;
	color: #A72126;
	margin: 0 auto;
	padding: .8em 2em;
	border: 1px solid;
	border-radius: 50px;
	&::after {
		content: "\f105";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		top: calc(50% - .5em);
		right: 1em;
		line-height: 1;
		font-size: 1.375em;
	}
}
/* ==================================================
layout
================================================== */
header,
footer,
#content > *,
#pttl,
#breadcrumb {
	padding-left: 20px;
	padding-right: 20px;
}
#gnav-wrap,
footer > *,
#content > * > *,
#pttl > * {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}
%ws > * {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}
/* ==================================================
header
================================================== */
header {
	position: relative;
	line-height: 1.15;
	padding-top: 50px;
	padding-bottom: 50px;
	a {
		text-decoration: none;
	}
}
#hlogo {
	line-height: 1.15;
	padding-left: 30px;
}
#hnav {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	li {
		width: 240px;
		&:nth-child(n+2) {
			margin-left: 15px;
		}
	}
	a {
		display: block;
		line-height: 2;
		font-size: 1.4rem;
		text-align: center;
		color: #A72126;
		padding: 1em;
		background: #fff;
		border: solid #A72126;
		border-width: 0 2px 2px;
		box-shadow: 0 4px 0 0 #E6E6E6;
		&.no02 {
			color : #fff;
			background: #A72126;
		}
		&::before {
			vertical-align: middle;
			margin-right: .5em;
		}
		@for $no from 1 through 2 {
			&.no0#{$no}::before {
				content: url("../img/hnav0#{$no}.svg");
			}
		}
	}
}
#gnav {
	display: flex;
	justify-content: space-between;
	max-width: 780px;
	margin: 50px 0 0 auto;
	padding-left: 30px;
	li {
		padding-bottom: .8em;
	}
	a:hover,
	.on a {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: -.8em;
			left: 0;
			width: 100%;
			height: 0;
			border-bottom: 1px solid #A72126;
		}
	}
}
/* ==================================================
footer
================================================== */
footer {
	line-height: 1.15;
	text-align: center;
	color: #fff;
	padding-top: 50px;
	padding-bottom: 30px;
	background: #A72126;
	a {
		text-decoration: none;
		color: inherit;
	}
}
#ptop {
	position: relative;
	a {
		display: block;
		position: absolute;
		top: -76px;
		right: 0;
		width: 53px;
		height: 53px;
		text-indent: 120%;
		white-space: nowrap;
		overflow: hidden;
		background: url("../img/ptop.svg") center / 100% no-repeat;
		&:hover {
			opacity: 1;
		}
	}
}
#fnav {
	li {
		display: inline;
		font-size: 1.3rem;
		margin: 0 1em;
	}
}
#flogo {
	margin-top: 50px;
}
#copyright {
	font-size: 1rem;
	margin-top: 50px;
}
/* ==================================================
main
================================================== */
#pttl {
	height: 300px;
	background: center / cover no-repeat;
	@for $no from 1 through 7 {
		&.no0#{$no} {
			background-image: url("../img/pttl-bg0#{$no}.jpg");
		}
	}
	> * {
		position: relative;
		height: 100%;
		> span {
			display: inline-block;
			position: absolute;
			bottom: 0;
			line-height: 1.4;
			font-size: 2.6rem;
			padding: .8em 2em .5em;
			background: #fff;
			box-shadow: 4px 0 0 0 rgba(0,0,0,.2);
		}
	}
}
#breadcrumb {
	text-align: right;
	margin-top: 1em !important;
	li {
		display: inline;
		font-size: 13px;
		color: #666;
		&:not(:last-child)::after {
			content: " >";
		}
		a {
			color: inherit;
		}
	}
}
#fbnr {
	@extend %ws;
	padding: 90px 20px;
	background: #F6F5F3;
	#top & {
		padding-top: 0;
		background: none;
	}
	> * {
		@extend .col;
		align-items: flex-start;
		background: #fff;
		#top & {
			background: #F6F5F3;
		}
		> figure {
			width: calc(370 / 900 * 100%);
			padding: 4px;
			background: #fff;
			border: 1px solid #ccc;
		}
		> div {
			width: calc(530 / 900 * 100%);
			font-size: 1.4rem;
			padding: 40px;
		}
		h2 {
			font-size: 3.4rem !important;
			text-align: inherit;
		}
		p {
			margin-top: .5em;
			&:last-child {
				margin-top: 2.5em;
			}
		}
		.btn {
			max-width: 195px;
			font-size: 1.2rem;
			color: inherit;
			margin-right: 0;
			padding-top: .5em;
			padding-bottom: .5em;
		}
	}
}
#content {
	@extend .clearfix;
	> * {
		padding-top: 90px;
		padding-bottom: 90px;
		&:first-child {
			padding-top: 40px;
		}
	}
	h2 {
		text-align: center;
	}
}
body:not(#top) #content {
	> * > * {
		&:nth-child(n+2) {
			margin-top: 60px;
		}
	}
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:nth-child(n+2) {
			margin-top: 2.5em;
		}
	}
	h2 {
		position: relative;
		font-size: 3rem;
		color: #A72126;
		padding-bottom: .5em;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: calc(50% - 27px);
			width: 55px;
			height: 0;
			border-bottom: 1px solid;
		}
		+ * {
			margin-top: 60px !important;
		}
	}
	p,
	ul,
	ol,
	dl,
	table,
	figure {
		&:nth-child(n+2) {
			margin-top: 1.5em;
		}
	}
	.note {
		font-size: 1.5rem;
		padding: 20px;
		border: 1px solid #E6E6E6;
	}
	.inquiry {
		padding: 30px;
		background: #F7F6F4;
		.ttl {
			position: relative;
			font-size: 2.4rem;
			text-align: inherit;
			color: #A72126;
			padding: 0 0 0 .5em;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 90%;
				border: none;
				border-left: 2px solid;
			}
			+ * {
				margin-top: 1.5em !important;
			}
		}
	}
}
/* base
============================== */
.base {
	h1 {
		font-size: 3rem;
		margin-top: 1em !important;
	}
	h3 {
		position: relative;
		font-size: 2.4rem;
		color: #A72126;
		padding-left: .5em;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 90%;
			border-left: 2px solid;
		}
	}
	h4 {
		font-weight: bold;
		+ * {
			margin-top: .5em !important;
		}
	}
	ul:not([class]) > *,
	ol:not([class]) > *,
	dl:not([class]) > dt {
		&:nth-child(n+2) {
			margin-top: .5em;
		}
	}
	ul:not([class]),
	ol:not([class]) {
		padding-left: 40px;
	}
	ul:not([class]) {
		list-style: disc;
	}
	ol:not([class]) {
		list-style: decimal;
	}
	dl:not([class]) > dt {
		font-size: 1.2em;
		font-weight: bold;
	}
}
/* top
============================== */
#kv {
	text-align: center;
	padding: 0 !important;
}
[id^="top-"] {
	h2 {
		font-size: 3.4rem;
		margin-bottom: 40px;
		span {
			@extend %font01;
			display: block;
			font-size: 1.4rem;
			color: #B3B3B3;
			margin-top: .5em;
		}
	}
}
#top-nav {
	text-align: center;
	margin-top: -100px;
	padding-top: 190px;
	background: #F6F5F3;
	ul {
		@extend .col;
		li {
			position: relative;
			width: 50%;
			margin-top: 0 !important;
			&::after {
				content: "";
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 29px 29px 0 0;
				border-color: #A72126 transparent transparent transparent;
			}
		}
		a {
			display: block;
			position: relative;
			z-index: 1;
			height: 100%;
			text-decoration: none;
			color: #fff;
			padding: 80px 20px;
			overflow: hidden;
			&:hover {
				opacity: 1;
			}
			&::before,
			&::after {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: center / cover no-repeat;
				transition: .5s;
			}
			&::after {
				background: rgba(0,0,0,.5);
			}
			&:hover::after {
				background: rgba(0,0,0,.8);
			}
			&::before {
				background: center / cover no-repeat;
			}
			@for $no from 1 through 4 {
				&.no0#{$no}::before {
					background-image: url("../img/top-nav-bg0#{$no}.jpg");
				}
			}
			&:hover::before {
				transform:  scale(1.1);
			}
		}
		.ttl {
			position: relative;
			line-height: 1.4;
			font-size: 4rem;
			padding-bottom: .5em;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: calc(50% - 27px);
				width: 55px;
				height: 0;
				border-bottom: 1px solid;
			}
			span {
				@extend %font01;
				display: block;
				font-size: 1.8rem;
			}
		}
		p:nth-child(n+2) {
			margin-top: 2.5em;
		}
		.btn {
			color: inherit;
		}
	}
}
#top-news {
	@extend %ws;
	ul {
		li {
			margin-top: 2px;
			&:first-child {
				margin-top: 0;
			}
		}
		a {
			display: flex;
			font-size: 1.4rem;
			text-decoration: none;
			> * {
				padding: 1.3em 1em;
			}
		}
		p {
			margin-top: 0 !important;
		}
		.cate {
			width: 10em;
			text-align: center;
			color: #fff;
			background: #4D4D4D;
		}
		.date,
		.txt {
			background: #F6F5F3;
		}
		.date {
			width: 9em;
			text-align: center;
			margin-left: 2px;
		}
		.txt {
			width: calc(100% - 19em - 2px);
		}
	}
	> p {
		margin-top: 40px !important;
	}
}
/* company
============================== */
#company-sec01 {
	h3 {
		font-size: 3.6rem;
	}
}
/* about
============================== */
#about-sec01 {
	h3 {
		font-size: 3.6rem;
	}
}
/* production
============================== */
#production-sec01 {
	h3 {
		font-size: 2.6rem;
	}
}
/* news
============================== */
#news-idx {
	@extend #top-news;
	position: relative;
	overflow-x: hidden;
	#archives-sec01 + &::after {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		width: 1000px;
		height: 0;
		border-top: 1px solid #F2F2F2;
		transform: translate(-50%,0);
	}
}
/* archives
============================== */
#archives-detail {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	p {
		margin-top: 0 !important;
	}
	> *:nth-child(n+2) {
		margin-left: 2em;
	}
	.cate {
		width: 10em;
		text-align: center;
		color: #fff;
		padding: 1.3em 1em;
		background: #4D4D4D;
	}
}

/* sample
============================== */
#sample-sec01 {
	h3 {
		font-size: 3.6rem;
	}
	.item601 {
		border: #a72126 1px solid;
		padding: 1.5em 1.0em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 600px;
		figure {
			width: 30%;
		}
		.txt {
			width: 65%;
			p {
				font-size: 18px;
				margin-bottom: 10px;
			}
			.btn {
				background-color: #a72126;
				color: #fff;
				margin: 0;
				max-width: 250px;
			}
		}
	}
	.sample-cnt01 {
		font-size: 19px;
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			li {
				width: 100%;
				max-width: 360px;
				margin: 0 15px;
				a {
					display: block;
					text-decoration: none;
					border: #a72126 1px solid;
					text-align: center;
					color: #a72126;
					padding: 1.25em .5em;
					position: relative;
					text-indent: -.75em;
					&::after {
						content: "\f105";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						top: calc(50% - .5em);
						right: .7em;
						line-height: 1;
						font-size: 1.375em;
					}
				}
				&:last-child {
					a {
						border: #2e3192 1px solid;
						color: #2e3192;
					}
				}
			}
		}
		.btn-back {
			width: 100%;
			max-width: 300px;
			margin: 40px auto 0;
			font-size: 17px;
			a {
				display: block;
				text-decoration: none;
				background-color: #f2f2f2;
				border-radius: 50px;
				text-align: center;
				padding: 0.75em .5em;
				position: relative;
				text-indent: -.75em;
			}
		}
	}
	.sample-cnt02 {
		h3 {
			font-size: 2.5rem;
			text-align: center;
			line-height: 1.7;
		}
		.img {
			text-align: center;
			padding: 20px 0 0;
			img {
				max-width:310px !important;
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			li {
				width: 26.5%;
				&:last-child {
					width: 29.7%;
				}
				img {
					width: 100%;
				}
				p {
					margin-top: 1.0em !important;
				}
			}
		}
	}
	.sample-cnt03 {
		.sample-cnt03-txt {
			background-color: #f8f8f8;
			h3 {
				font-size: 2.5rem;
				color: #a72126;
				text-align: center;
				padding: 30px 0 0;
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 0px 40px 30px;
				li {
					width: 30%;
					h4 {
						text-align: center;
						font-size: 1.8rem;
						font-weight: bold;
					}
					p {
						font-size: 1.6rem;
						margin-top: 0.5em !important;
					}
				}
			}
		}
		.sample-cnt03-img {
			padding: 30px 0 0 0;
			text-align: center;
		}
	}
}
/* map
============================== */
.map-idx .txt {
  text-align: center;
  margin-bottom: 15px;
}
.map-idx .txt.color_red {
  border: #A72126 solid 1px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.map-idx .txt.color_red a {
  color: #A72126;
}
.map-idx .tit {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1.2em;
}
.map-idx dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px #ccc solid;
  margin:  0 !important;
  padding: 1.2em;
}
.map-idx dl dt {
  padding-left: 30px;
  position: relative;
}
.map-idx dl dt::before {
  content: "";
  background: url("../img/maker.png") 0 0 no-repeat;
  width: 24px;
  height: 36px;
  position: absolute;
  top: -4px;
  left: 0;
}

/*# sourceMappingURL=shared.css.map */

/* history */
body:not(#top) #content table:nth-child(n+2).history {
  margin-top: 3.0em;
  border-spacing: 0;
}
body:not(#top) #content table:nth-child(n+2).history th {
  text-align: right;
  line-height: 1.3;
  vertical-align: top;
  border-right: #999 dotted 1px;
}
body:not(#top) #content table:nth-child(n+2).history th span.number {
  font-size: 24px;
  color:  #000;
  display: block;
}
body:not(#top) #content table:nth-child(n+2).history th span.ja {
  font-size: 12px;
  color: #808080;
}
body:not(#top) #content table:nth-child(n+2).history tr:nth-child(odd) th,
body:not(#top) #content table:nth-child(n+2).history tr:nth-child(odd) td {
  background-color: #fafafa;
}
body:not(#top) #content table:nth-child(n+2).history tr:nth-child(even) th,
body:not(#top) #content table:nth-child(n+2).history tr:nth-child(even) td {
  background-color: #fff;
}
body:not(#top) #content table:nth-child(n+2).history td img {
  margin-top: 15px;

}
